import { atom } from "recoil";
import { BackendState, ErrorMessage, Pages } from "../types";

export const connectionStateAtom = atom<BackendState>({
  key: "backend_state",
  default: BackendState.NotConnected,
});

// Currently selected and rendered page.
export const currentPageAtom = atom<Pages>({
  key: "current_page",
  default: Pages.Login,
});

export const errorMessageAtom = atom<ErrorMessage | null>({
  key: "error_message",
  default: null,
});
