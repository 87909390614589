import { useState } from "react";
import { RecoilRoot, useRecoilValue } from "recoil";
import { useTranslations } from "./hooks";
import { Credentials, useGetWorkers } from "./state/hook";
import { connectionStateAtom } from "./state/misc";
import { LoginView } from "./views/LoginView";
import { ChooseWorker } from "./views/ChooseWorker";
import { Scaffold } from "./views/Scaffold";
import { IntlProvider } from "react-intl";
import { MdLogout } from "react-icons/md";
import { UserInfoWorkerResponse } from "./backend/types";
import { parseInt } from "lodash";

const ConnectionInfo = () => {
  const state = useRecoilValue(connectionStateAtom);
  return <div className="w-auto fixed bottom-2 left-2 font-bold font-mono text-dna-textcolor">CS: {state}</div>;
};

const SignoutButton = ({ onLogout }: { onLogout: () => void }) => {
  const t = useTranslations();
  return (
    <button className="fixed bottom-1 right-1 w-auto flex items-center gap-x-1 hover:cursor-pointer" onClick={onLogout}>
      <MdLogout /> {t({ id: "Signout" })}
    </button>
  );
};
const LOCALSTORAGE_KEY = "_dstm";
const InnerApp = () => {

  const [credentials, setCredentials] = useState<Credentials>({
    username: "",
    password: "",
  });

  const onLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  let search = "";
  let href = window.location.href;

  if (window.location.href.includes('#')) {
    let parts = window.location.href.split('#');
    href = parts[0];
    search = '?' + parts[1];
  }

  let params = new URLSearchParams(search);

  if (credentials.username == "" && credentials.password == "") {
    let user = params.get('us');
    let pw = params.get('pw');
    let logout = params.get('logout');
    if (logout) {
      localStorage.clear();
      window.location.assign(href);
    }
    if (user && pw) {
      setCredentials({
        username: user,
        password: pw
      });
    }
  }

  let id = params.get('id');
  let gate = params.get('gate');

  const handleWorkerChosen = (url: string) => {
    const { at, rt, exp } = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) || "");

    let u = url + `#at=${at}&rt=${rt}&exp=${exp}`;
    if (gate) u += `&gate=${gate}`;
    window.location.assign(u);
    return;
  };

  const t = useTranslations();

  const workers: UserInfoWorkerResponse[] | null = useGetWorkers({ ...credentials });

  if (workers) {
    if (id && !isNaN(parseInt(id))) {
      let idNum = parseInt(id);
      let w = workers.find(a => a.id == idNum);
      if (w != null) {
        handleWorkerChosen(w.clientUrl);
      }
    }
    if (workers.length == 1) {
      handleWorkerChosen(workers[0].clientUrl);
    }
  }

  const hasWorkers = !!workers;

  if (hasWorkers && !Array.isArray(workers)) {
    onLogout();
    return null;
  }
  const isDev = process.env.NODE_ENV !== "production";

  // Render current page.
  const content = (() => {
    if (!hasWorkers) {
      return {
        content: <LoginView onSetCredentials={setCredentials} />,
        title: t({ id: "DebugPageTitle" }),
      };
    } else {
      return {
        content: <ChooseWorker handleWorkerChosen={handleWorkerChosen} workers={workers} />,
        title: "Valitse tili"
      };
    }

  })();

  return (
    <>
      <IntlProvider locale="fi" defaultLocale="fi">
        <Scaffold showNav={false} pageTitle={content.title}>
          {content.content}
          {hasWorkers && <SignoutButton onLogout={onLogout} />}
        </Scaffold>
        {isDev && <ConnectionInfo />}
      </IntlProvider>
    </>
  );
};

export const App = () => {
  return (
    <RecoilRoot>
      <InnerApp />
    </RecoilRoot>
  );
};

export default App;
