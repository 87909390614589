import React from "react";
import { Header } from "../components/Header";
import Logo from "../components/Logo";
import Main from "../components/Main";
import "../styles/kanban.css";

type Props = {
  children: React.ReactNode;
  pageTitle: string;
  showNav: boolean;
};
export const Scaffold = ({ children, pageTitle }: Props) => {
  const logo = <Logo />;
  const title = <>{pageTitle}</>;
  return (
    <div className="flex flex-col w-full h-full">
      <Header logo={logo} title={title} />
      <Main>{children}</Main>
    </div>
  );
};

export default Scaffold;
