import clsx from "clsx";
import React from "react";

type Props = { children?: React.ReactNode; className?: string };

export const Main = ({ children, className }: Props) => {
  return <main className={clsx("flex-auto p-7", className)}>{children}</main>;
};

export default Main;
