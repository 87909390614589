import {
  AuthInfo,
  FetchSocketUrlResponse,
  UserInfoUserResponse,
  UserInfoWorkerResponse,
} from "./types";

export const fetchSocketUrl = async ({ password, username, authUrl, infoUrl }: AuthInfo & { authUrl: string; infoUrl: string }): Promise<FetchSocketUrlResponse | Error> => {
  const authBody = {
    grant_type: "password",
    client_id: "dna-iot",
    username: username,
    password: password,
  };
  const r1 = await fetch(authUrl, {
    method: "POST",
    body: JSON.stringify(authBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (r1.status !== 200) {
    console.error({
      status: r1.status,
    });
    return Error(`Failed to authenticate`);
  }

  const {
    token_type: tokenType,
    expires_in: expiresIn,
    access_token: accessToken,
    refresh_token: refreshToken,
  } = await r1.json();

  const infoBody = {
    access_token: accessToken,
  };
  const r2 = await fetch(infoUrl, {
    method: "POST",
    body: JSON.stringify(infoBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (r2.status !== 200) {
    console.error({
      status: r2.status,
    });
    return Error(`Failed to fetch socket URL`);
  }

  const { user, workers } = (await r2.json()) as {
    user: UserInfoUserResponse;
    workers: UserInfoWorkerResponse[];
  };
  const {
    id: userId,
    name: userName,
    email: userEmail,
    userName: userName2, // What is this used for?
  } = user;
  if (!workers.length) {
    return Error("No worker nodes");
  }

  return {
    expiresIn,
    expiresTs: Math.floor(Date.now() + expiresIn * 1000),
    workers,
    userId,
    userName,
    userEmail,
    accessToken,
    refreshToken,
  };
};
