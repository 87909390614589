import { useState } from "react";
import { useRecoilValue } from "recoil";
import Button from "../components/Button";
import { Input } from "../components/Input";
import { useFormFocus, useTranslations } from "../hooks";
import { Credentials } from "../state/hook";
import { connectionStateAtom } from "../state/misc";
import { BackendState } from "../types";

export const LoginView = ({ onSetCredentials }: { onSetCredentials: (x: Credentials) => void }) => {
  const authState = useRecoilValue(connectionStateAtom);
  const t = useTranslations();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    if (!username) return null;
    if (!password) return null;
    onSetCredentials({
      username,
      password,
    });
  };

  const { setFormFocus } = useFormFocus({ onSubmit: handleSubmit });

  const isDisabled = [
    BackendState.Communicating,
    BackendState.Connecting,
    BackendState.FetchingUserInfo,
    BackendState.Connected,
    BackendState.ConnectedAndAuthenticated,
    BackendState.Authenticating,
  ].includes(authState);

  const isError = [BackendState.AuthenticationError].includes(authState);

  return (
    <section className="flex flex-col max-w-md m-auto mt-32">
      <header className="mb-2">
        <h1 className="text-3xl font-bold text-center">Kirjaudu sisään</h1>
      </header>
      <div className="card flex flex-col gap-y-2 px-3 pt-14 pb-12 items-center">
        <Input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onFocusChange={setFormFocus}
          placeholder={t({
            id: "Credentials.Username",
          })}
        />
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onFocusChange={setFormFocus}
          placeholder={t({
            id: "Credentials.Password",
          })}
        />
        <Button
          text={t({
            id: "Credentials.Submit",
          })}
          variant="primary"
          disabled={isDisabled}
          className="mt-5"
          onClick={handleSubmit}
        />
      </div>
      {isError && <p className="font-bold text-red-500">Kirjautuminen epäonnistui</p>}
    </section>
  );
};
