import clsx from "clsx";
import Loader from "./Loader";

type Props = {
  text?: string;
  variant: "primary" | "secondary";
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
  children?: any;
  disabled?: boolean;
  isLoading?: boolean;
};

export function Button({
  onClick,
  text,
  variant = "primary",
  className,
  icon,
  children,
  disabled = false,
  isLoading = false,
  ...props
}: Props) {
  const getVariantClass = () => {
    if (disabled) {
      return "bg-dna-vaaleanharmaa text-white";
    }
    if (variant === "primary") {
      return "bg-dna-kuumapinkki text-white hover:bg-dna-kuumapinkki-dark border-dna-kuumapinkki";
    } else {
      return "bg-white hover:bg-dna-vaaleanharmaa";
    }
  };

  return (
    <button
      onClick={onClick}
      className={clsx(
        "border px-6 py-1 text-md font-bold disabled:opacity-90 disabled:cursor-not-allowed",
        getVariantClass(),
        className,
      )}
      {...props}
      disabled={disabled}
    >
      {isLoading ? (
        <Loader variant={variant} />
      ) : (
        <>
          {text}
          {icon && icon}
          {children}
        </>
      )}
    </button>
  );
}

export default Button;
