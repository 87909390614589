import React from "react";
import styles from "../styles/Header.module.css";
import clsx from "clsx";

type Props = {
  logo?: React.ReactElement;
  title?: React.ReactNode;
  nav?: React.ReactElement;
};

export const Header = ({ logo, title, nav }: Props) => {
  return (
    <header className={clsx("flex flex-nowrap flex-row gap-x-4 card", styles.header)}>
      {logo && <div className="w-auto">{logo}</div>}
      {title && <div className="flex-auto flex items-center text-3xl font-bold">{title}</div>}
      {nav && <div className="mr-5 w-auto">{nav}</div>}
    </header>
  );
};

export default Header;
