import { ReactComponent as DnaLogo } from "../dna-logo.svg";

export const Logo = () => {
  return (
    <>
      <DnaLogo />
    </>
  );
};

export default Logo;
