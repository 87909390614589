import React, { useState } from "react";
import Select from "react-select";
import { Translations } from "../hooks";

export const FixedSelect = ({
  t,
  tabIndex,
  height,
  error,
  showInitialError,
  onChange,
  onFocusChange,
  ...props
}: React.ComponentProps<Select> & {
  t: Translations;
  onFocusChange?: (fn: (i: number) => number) => void;
  height: number;
  error?: string | boolean;
  showInitialError?: boolean;
}) => {
  const [isFocused, setFocused] = useState(false);
  const isError = (typeof error === "boolean" && error === true) || (typeof error === "string" && error.length > 0);
  const handleChange: typeof onChange = (...args) => {
    onChange?.(...args);
  };

  const sHalfHeight = `-${12}px`; // Hacky fix, issue seems to be within react-select, hardcoded margin to fix.

  const styles = {
    control: (style: any) => ({
      // none of react-select's styles are passed to <Control />
      ...style,
      height,
      borderRadius: 2,
      borderColor: isError ? "rgb(220 38 38)" : "#e0e1dd",
      borderWidth: isError ? "2px" : style.borderWidth,
      width: "auto",
    }),
    indicatorsContainer: (style: any) => ({
      ...style,
      width: "auto",
      height,
    }),
    input: (styles: any) => ({
      ...styles,
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      height,
    }),
    placeholder: (styles: any) => ({
      ...styles,
      marginTop: isFocused ? styles.marginTop || null : sHalfHeight,
    }),
    singleValue: (styles: any, state: any) => ({
      ...styles,
      marginTop: isFocused ? styles.marginTop || null : sHalfHeight,
    }),
  };
  return (
    <div>
      <Select
        styles={styles}
        {...props}
        onFocus={() => {
          setFocused(true);
          if (onFocusChange) onFocusChange((i: number) => i + 1);
        }}
        onBlur={() => {
          setFocused(false);
          if (onFocusChange) onFocusChange((i: number) => i + 1);
        }}
        onChange={handleChange}
      />
    </div>
  );
};

export default FixedSelect;
