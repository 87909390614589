import clsx from "clsx";
import { useState } from "react";

type Props = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onFocusChange?: (fn: (i: number) => number) => void;
  className?: string;
  error?: string | boolean;
  showInitialError?: boolean;
};
export const Input = ({
  className,
  onFocusChange,
  onChange,
  error,
  disabled,
  showInitialError,
  ...props
}: Omit<React.HTMLProps<HTMLInputElement>, "onChange"> & Props) => {
  const [hasChanged, setHasChanged] = useState(false);
  const canShowError = showInitialError || hasChanged;
  const isError =
    (!disabled && typeof error === "boolean" && error === true) || (typeof error === "string" && error.length > 0);
  const errorString = isError ? (typeof error === "string" ? error : null) : null;
  const handleChange: typeof onChange = (e) => {
    setHasChanged(true);
    onChange?.(e);
  };
  return (
    <div>
      <input
        disabled={disabled}
        onChange={handleChange}
        onFocus={onFocusChange ? () => onFocusChange((i: number) => i + 1) : undefined}
        onBlur={onFocusChange ? () => onFocusChange((i: number) => i - 1) : undefined}
        className={clsx(
          "px-4 disabled:cursor-not-allowed disabled:bg-dna-vaaleanharmaa",
          className,
          isError && canShowError && "border-red-600 border-2",
        )}
        {...props}
      />
      {canShowError && errorString && <p className="h-0 left-1 text-red-600 leading-tight">{errorString}</p>}
    </div>
  );
};
