import { Workers } from "./backend/state-handler";
import { Translations } from "./hooks";

export const COLORS = {
  Dna: {
    VedenSininen: "#007D91",
    MeloninKeltainen: "#FFA100",
    KivenHarmaa: "#4B5055",
    VaaleanHarmaa: "#E0E1DD",
    Musta: "#000000",
    PuhdasValkoinen: "#FFFFFF",
    KuumaPinkki: "#FF007D",
    BackgroundGray: "#F7F7F7",
    TextColor: "#121212",
  },
};

export enum Pages {
  Login = "login",
  ChooseWorker = "chooseWorker"
}

export enum BackendState {
  NotConnected = "NotConnected",
  Authenticating = "FetcingToken",
  FetchingUserInfo = "FetchingUserInfo",
  Connecting = "SocketConnecting",
  Connected = "SocketConnected",
  ConnectedAndAuthenticated = "SocketConnectedAndAuthenticated",
  Closed = "SocketClosed",
  Communicating = "SocketCommunicating",
  AuthenticationError = "AuthError",
  SocketError = "SocketError",
}

export enum Languages {
  Finnish = "fi",
}

export type Sorter = "desc" | "asc";

type ConsoleLogParams = typeof console["log"];
type FnLog = ConsoleLogParams;
export type Logger = {
  log: FnLog;
  warn: FnLog;
  error: FnLog;
};

export type ServiceContainer = {
  logger: Logger;
  workers: Workers | null;
  constants: {
    dev: boolean;
  };
  t: Translations;
  colors: typeof COLORS;
};

export type ServiceProps = {
  services: ServiceContainer;
};

export type FnSetConnectionState = (x: BackendState) => void;

export type ErrorMessage = {
  title?: string;
  message: string;
  details?: string;
};
