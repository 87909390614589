import { isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { translationsAtom, TranslationsKeys } from "./state/translations";

export function useDeepCompareMemoize(value: any) {
  const ref = useRef();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export type Translations = ReturnType<typeof useTranslations>;
export const useTranslations = () => {
  const translations = useRecoilValue(translationsAtom);
  return ({ id }: { id: TranslationsKeys }) => {
    return translations[id] || `MISSING_TRANSLATION: ${id}`;
  };
};

export function useIsMounted(ref: React.MutableRefObject<boolean>) {
  if (!ref) throw Error("Null ref");
  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
}

export const useFormFocus = ({ onSubmit }: { onSubmit: () => void }) => {
  const [formFocus, setFormFocus] = useState(0);
  const hasFormFocus = formFocus > 0;
  useEffect(() => {
    const handler = (e: any) => {
      if (e.key === "Enter" && hasFormFocus) {
        onSubmit();
      }
    };
    document.addEventListener("keydown", handler);
    return document.removeEventListener.bind(document, "keydown", handler);
  }, [hasFormFocus, onSubmit]);
  return { hasFormFocus, setFormFocus };
};
