import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchSocketUrl } from "../backend/rest";
import { Workers } from "../backend/state-handler";
import { BackendState } from "../types";
import { UserInfoWorkerResponse } from "../backend/types";
import { connectionStateAtom } from "./misc";
import env from "react-dotenv";

export type Credentials = {
	username: string;
	password: string;
};

export const useGetWorkers = ({ username, password }: Credentials): Workers | null => {

	const [workers, setWorkers] = useState<Workers | null>();

	const setConnectionState = useSetRecoilState(connectionStateAtom);

	const LOCALSTORAGE_KEY = "_dstm";

	useEffect(() => {
		(async () => {
			try {
				let previousAuth = null;

				let accessToken: string | null = null;
				let refreshToken: string | null = null;
				let expiresTs: number | null = null;
				let workersList: UserInfoWorkerResponse[] | null = null;

				try {
					const { at, rt, exp, wu } = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) || "");
					if (at && rt && wu && exp) {
						previousAuth = true;
						accessToken = at;
						refreshToken = rt;
						expiresTs = exp;
						workersList = wu;
					}
				} catch (e) {
					// Ignore.
				}

				let authUrl = env.REACT_APP_AUTH_URL as string;
				let infoUrl = env.REACT_APP_INFO_URL as string;

				const updateStoredAuth = ({ expiresTs, accessToken, refreshToken, workers }: { expiresTs: number; accessToken: string; refreshToken: string; workers: UserInfoWorkerResponse[]; }) => {

					window.localStorage.setItem(LOCALSTORAGE_KEY,
						JSON.stringify({
							exp: expiresTs,
							at: accessToken,
							rt: refreshToken,
							wu: workers,
						})
					);
				};

				if (!previousAuth) {
					if (!username || !password) {
						// Credentials not provided.
						setWorkers(null);
						return;
					}

					setConnectionState(BackendState.Authenticating);
					const r1 = await fetchSocketUrl({
						authUrl,
						infoUrl,
						username,
						password,
					});
					if (r1 instanceof Error) throw r1;

					updateStoredAuth(r1);

					accessToken = r1.accessToken;
					refreshToken = r1.refreshToken;
					expiresTs = r1.expiresTs;
					workersList = r1.workers;
				}

				if (!accessToken || !refreshToken || !workersList || !expiresTs) {
					setConnectionState(BackendState.AuthenticationError);
					console.error("Failed to authenticate", {
						accessToken,
						refreshToken,
						workersList,
					});
					localStorage.clear();
					throw Error("Failed to authenticate");
				}

				setConnectionState(BackendState.Connecting);
				setWorkers(workersList);

			} catch (e) {
				console.error("Exception while authenticating", e);
				setConnectionState(BackendState.AuthenticationError);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username, password]);

	return workers || null;
};
