import { atom, selector } from "recoil";
import TranslationsFinnish from "../translations/fi.json";
import { Languages } from "../types";

// TODO: More sophisticated translation management.
export const currentLanguageAtom = atom<Languages>({
  key: "current_language",
  default: Languages.Finnish,
});
type Translations = Record<string, string>;
export const translationsAtom = selector<Translations>({
  key: "translations",
  get: ({ get }) => {
    const lan = get(currentLanguageAtom);
    switch (lan) {
      default:
      case Languages.Finnish:
        return TranslationsFinnish;
    }
  },
});
export const DefaultTranslations = TranslationsFinnish;
export type TranslationsKeys = keyof typeof DefaultTranslations;
