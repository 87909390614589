import { useState } from "react";
import Button from "../components/Button";
import { useTranslations, useFormFocus } from "../hooks";
import { UserInfoWorkerResponse } from "../backend/types";
import FixedSelect from "../components/Select";

export const ChooseWorker = ({ handleWorkerChosen, workers }: { handleWorkerChosen: (x: string) => void; workers: UserInfoWorkerResponse[] }) => {
  const t = useTranslations();
  const [clientUrl, setWorker] = useState(workers[0].clientUrl);

  const handleSubmit = () => {
    if (!clientUrl) return null;
    handleWorkerChosen(clientUrl);
  };

  const { setFormFocus } = useFormFocus({ onSubmit: handleSubmit });

  let options = workers.map(a => ({ value: a.clientUrl, label: a.name }));
  return (
    <section className="flex flex-col max-w-md m-auto mt-32">
      <header className="mb-2">
        <h1 className="text-3xl font-bold text-center">Valitse tili</h1>
      </header>
      <div className="card flex flex-col gap-y-2 px-3 pt-14 pb-12 items-center">

        <FixedSelect
          t={t}
          height={36}
          isDisabled={false}
          options={options}
          value={options.filter(a => a.value == clientUrl)}
          onFocusChange={setFormFocus}
          onChange={(e: any) => {
            if (e) {
              setWorker(e?.value);
            }
          }}
        />
        <Button
          text={t({
            id: "Credentials.Submit",
          })}
          variant="primary"
          disabled={false}
          className="mt-5"
          onClick={handleSubmit}
        />
      </div>
    </section>
  );
};
